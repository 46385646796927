import React, { Fragment } from 'react'
import MetaTags from 'react-meta-tags'
import LayoutOne from '../../layouts/LayoutOne'
import ContentWrapper from '../../components/dynamicMetaPage/ContentWrapper'

export default function RefundPolicy () {  
    const appDetails = JSON.parse(process.env.REACT_APP_APP_DETAILS);
  const metaTags = <MetaTags>
      <title>{appDetails.name} | Terms and Conditions</title>
      <meta
        name="description"
        content={appDetails.name}
      />
    </MetaTags>

  return (
    <Fragment>
      <ContentWrapper>{metaTags}</ContentWrapper>

      <LayoutOne 
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
        headerTop="visible"
      >        
        <div style={styles.container}>
      <h1 style={styles.heading}>Ekki - Refund Policy</h1>
      <p>
        At <span style={styles.brand}>Ekki</span>, customer satisfaction is our priority. This Refund Policy outlines the circumstances under which refunds may be issued and the process for requesting one. Please read it carefully to understand your rights and obligations.
      </p>

      <h2 style={styles.subheading}>1. Eligibility for Refunds</h2>
      <p>Refunds may be considered in the following cases:</p>
      <ul style={styles.list}>
        <li><strong>Service Issues:</strong> If a service paid for was not delivered as promised.</li>
        <li><strong>Technical Errors:</strong> If a payment was made due to a technical error or duplicate transaction.</li>
        <li><strong>Unauthorized Transactions:</strong> If a transaction was completed without the account holder’s authorization.</li>
      </ul>

      <h2 style={styles.subheading}>2. Non-Refundable Transactions</h2>
      <p>Refunds will not be issued in the following circumstances:</p>
      <ul style={styles.list}>
        <li>If the service or product has been delivered and used as intended.</li>
        <li>For dissatisfaction arising from issues outside Ekki’s control (e.g., user error, third-party delays).</li>
        <li>For promotional or discounted transactions, unless explicitly stated.</li>
      </ul>

      <h2 style={styles.subheading}>3. Refund Request Process</h2>
      <p>To request a refund, follow these steps:</p>
      <ul style={styles.list}>
        <li>Submit a written request via email to <a href="mailto:support@ekki.com.ng" style={styles.link}>support@ekki.com.ng</a> within 14 days of the transaction.</li>
        <li>Include the following details:
          <ul>
            <li>Transaction ID</li>
            <li>Date of transaction</li>
            <li>Reason for refund request</li>
            <li>Supporting documentation, if applicable</li>
          </ul>
        </li>
        <li>Our team will review your request and respond within 7 business days.</li>
      </ul>

      <h2 style={styles.subheading}>4. Refund Approval and Processing</h2>
      <p>
        Once your refund is approved:
      </p>
      <ul style={styles.list}>
        <li>The refund will be processed back to the original payment method used.</li>
        <li>Processing times may vary depending on your financial institution, typically taking 5-10 business days.</li>
        <li>You will receive a confirmation email once the refund has been initiated.</li>
      </ul>

      <h2 style={styles.subheading}>5. Disputes and Chargebacks</h2>
      <p>
        If you have initiated a chargeback with your payment provider, please notify us immediately. We will work with you and the provider to resolve the issue promptly.
      </p>

      <h2 style={styles.subheading}>6. Contact Information</h2>
      <p>
        If you have questions or need further clarification regarding this Refund Policy, please reach out to us:
      </p>
      <ul style={styles.list}>
        <li>Email: <a href="mailto:support@ekki.com.ng" style={styles.link}>support@ekki.com.ng</a></li>
        <li>Phone: +234-816-952-2790</li>
        <li>Website: <a href="https://ekki.com.ng" style={styles.link}>https://ekki.com.ng</a></li>
      </ul>

      <p>
        We value your trust and aim to resolve any issues to your satisfaction. Thank you for choosing Ekki.
      </p>
    </div>
      </LayoutOne>
    </Fragment>
  )
}

const styles = {
  container: {
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    fontFamily: "'Arial', sans-serif",
    lineHeight: "1.8",
    color: "#333",
  },
  heading: {
    color: "#2a9d8f",
    textAlign: "center",
    marginBottom: "20px",
  },
  subheading: {
    color: "#e76f51",
    marginTop: "20px",
    marginBottom: "10px",
  },
  brand: {
    fontWeight: "bold",
    color: "#264653",
  },
  link: {
    color: "#2a9d8f",
    textDecoration: "none",
  },
  list: {
    marginLeft: "20px",
    listStyleType: "disc",
  },
};