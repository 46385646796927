import React from "react";
import { useSelector } from "react-redux";

const MobileWidgets = () => {
  const shop = useSelector((state)=> state?.shop);

  return (
    <div className="offcanvas-widget-area">
      {
        shop && 
        <>
          <div className="off-canvas-contact-widget">
            <div className="header-contact-info">
              <ul className="header-contact-info__list">
                <li>
                  <i className="fa fa-phone"></i>{" "}
                  {/* <a href="tel://+2348169522790">{shop.phone} </a> */}
                  <a href={`tel://${shop.phonePrefix}${shop.phone}`}>{shop.phone} </a>
                </li>
                <li>
                  <i className="fa fa-envelope"></i>{" "}
                  <a href={`mailto:${shop.email}`}>{shop.email}</a>
                </li>
              </ul>
            </div>
          </div>
          {/*Off Canvas Widget Social Start*/}
          <div className="off-canvas-widget-social">
            <a href="#/" title="Twitter">
              <i className="fa fa-twitter"></i>
            </a>
            <a href="#/" title="Instagram">
              <i className="fa fa-instagram"></i>
            </a>
            <a href="https://www.facebook.com/profile.php?id=100089041944492" title="Facebook">
              <i className="fa fa-facebook"></i>
            </a>
            <a href="#/" title="Pinterest">
              <i className="fa fa-pinterest"></i>
            </a>
          </div>
          {/*Off Canvas Widget Social End*/}
        </>
      }
    </div>
  );
};

export default MobileWidgets;
