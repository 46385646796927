import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import "./SantaHat.css"; // Create this CSS file for animations

export default function Logo ({ logoClass, imageUrl}){
  const shop = useSelector((state)=> state.shop);  
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  // Ensure startDate and endDate use the current year
  const startDate = new Date(`${currentYear}-12-01`);
  const endDate = new Date(`${currentYear}-12-31`);
  const canShowOverlayLogo = currentDate >= startDate && currentDate <= endDate;
  const overLayLogoSrc = '/assets/img/logo/santa_hat-removebg-preview.png';

  if(!shop){
    return ''
  }

  return <div className={`${logoClass ? logoClass : ''}`} style={{paddingTop: 3}}>
      <Link to={'/'}>
        <div className='row m-0 pl-0'>
            <div className='col-sm-3 text-center_del p-0 pt-0'>
              <img 
                alt="" 
                src={imageUrl || process.env.REACT_APP_API_ASSETS_PATH+shop.defaultImage}
                style={{width: 60, height: 60}} 
                onError={(e) => {
                  e.target.src = `${process.env.REACT_APP_DEFAULT_SHOP_LOGO_PATH}`; 
                  e.target.onError = null;
                }} 
              />    

              {
                canShowOverlayLogo && 
                <img
                  src={overLayLogoSrc} 
                  alt="Santa Hat"
                  className="santa-hat" // Apply the animation class
                />
              }

            </div>
            <div className='col-sm-9 p-0 pt-2 pl-2 d-none d-sm-block'>
              {shop.name.substring(0,4)}
            </div>
        </div>
      </Link>
    </div>
}