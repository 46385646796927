import React, { useState} from "react";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import axios from 'axios';
import { phoneNumberValidationSchema } from "./validation-schemas/LoginRegister";
import { storeUser } from "../../helpers/utils";

export default function PhoneNumberForm ({user, token, setShowModal, setUser}) {
  const appDetails = JSON.parse(process.env.REACT_APP_APP_DETAILS);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
 
  const onSubmit = async (values, actions) => {
    try{
      const { phone } = values;
      setMessage('');      
      setLoading(true);
      const res = await axios.put(`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}user/update/${user.slug}`, {
          phone
      },{
        headers:{
          Authorization: `Bearer ${token}`
        }
      });
      setLoading(false);
      if(res.status === +process.env.REACT_APP_RECORD_NOT_FOUND){        
        addToast('Invalid details provided', { appearance: "error", autoDismiss: true });
      }else{
        const { error, message } = res.data;    
        if(!error){        
          user.phone = phone;    
          storeUser({accessToken: token, user, setShowModal, actions, setUser});
          setShowModal(false);          
        }else{
          setMessage(message);
          addToast(message, { appearance: "error", autoDismiss: true });
        }
      }  
    }catch(err){
      setLoading(false);
      console.error(err, err.response);
      const message = err.response.data.message || err.message;
      setMessage(message);
      addToast(message, { appearance: "error", autoDismiss: true });
    }
  };

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        phone: "",
      },
      validationSchema: phoneNumberValidationSchema,
      onSubmit,
    });

  const { phone } = values;

  return (
    <div>
      <h1>Welcome, {user.name}</h1>
      <h3>Please take a minute to update your phone number</h3>
      <h3>Set a phone number</h3>
      <h5>Your phone number is used to personalize your experiences and to contact you when neccessary by {appDetails.name}, and for more relevant information.</h5>
      {!user.phone ? (
        <div>
          <form onSubmit={handleSubmit}>      
            <div>
            <label>
              Phone Number:
              <input
                type='number'
                name='phone'
                placeholder='Enter your Phone Number'
                onChange={(e)=>{
                  handleChange(e);
                  setMessage('');
                }}
                onBlur={handleBlur}
                value={phone}
              />
              </label>
              {errors?.phone && touched?.phone && 
                  <span className='text-danger'>{errors?.phone}</span>
              }
            </div>
            {
              message && 
              <div className="text-center">{message}</div>
            }
            <div className='button-box'>
              <button type='submit' className="btn btn-lg btn-info">
                <span>{loading ? 'Please Wait...' : 'Update Phone Number'}</span>
              </button>
            </div>
          </form>
        </div>
      ) : (
        <p>Phone Number: {user.phone}</p>
      )}
    </div>
  );
};