import React, { Fragment } from 'react'
import MetaTags from 'react-meta-tags'
import LayoutOne from '../../layouts/LayoutOne'
import ContentWrapper from '../../components/dynamicMetaPage/ContentWrapper'

export default function PrivacyPolicy () {  
    const appDetails = JSON.parse(process.env.REACT_APP_appDetails);
  const metaTags = <MetaTags>
      <title>{appDetails.name} | Terms and Conditions</title>
      <meta
        name="description"
        content={appDetails.name}
      />
    </MetaTags>

  return (
    <Fragment>
      <ContentWrapper>{metaTags}</ContentWrapper>

      <LayoutOne 
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
        headerTop="visible"
      >
            <div style={styles.container}>
                <h1 style={styles.heading}>Ekki - Privacy Policy</h1>
                <p>
                    Welcome to <span style={styles.brand}>Ekki</span>. Your privacy is critically important to us.
                    This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our platform. Please read this policy carefully to understand our practices.
                </p>

                <h2 style={styles.subheading}>1. Information We Collect</h2>
                <p>
                    We may collect personal information directly from you or automatically when you use our platform. This includes:
                </p>
                <ul style={styles.list}>
                    <li><strong>Personal Information:</strong> Name, email address, phone number, and billing information.</li>
                    <li><strong>Account Details:</strong> Username, password, and preferences.</li>
                    <li><strong>Usage Data:</strong> IP addresses, browser type, and device information.</li>
                    <li><strong>Transaction Data:</strong> Payment details, transaction history, and order information.</li>
                </ul>

                <h2 style={styles.subheading}>2. How We Use Your Information</h2>
                <p>
                    The information we collect is used to:
                </p>
                <ul style={styles.list}>
                    <li>Provide, operate, and maintain our platform.</li>
                    <li>Process transactions and send confirmations.</li>
                    <li>Communicate with you about updates, promotions, and support.</li>
                    <li>Improve our platform through analytics and feedback.</li>
                    <li>Ensure security and prevent fraudulent activity.</li>
                </ul>

                <h2 style={styles.subheading}>3. Sharing Your Information</h2>
                <p>
                    We do not sell your personal information. However, we may share it with:
                </p>
                <ul style={styles.list}>
                    <li><strong>Service Providers:</strong> Third parties that assist us in providing services such as payment processing, hosting, and analytics.</li>
                    <li><strong>Legal Authorities:</strong> To comply with legal obligations or respond to lawful requests.</li>
                    <li><strong>Business Transfers:</strong> In case of a merger, acquisition, or sale of assets.</li>
                </ul>

                <h2 style={styles.subheading}>4. Data Retention</h2>
                <p>
                    We retain your information for as long as necessary to fulfill the purposes outlined in this policy unless a longer retention period is required by law.
                </p>

                <h2 style={styles.subheading}>5. Your Rights</h2>
                <p>
                    Depending on your location, you may have the following rights:
                </p>
                <ul style={styles.list}>
                    <li>Access your personal information and request a copy.</li>
                    <li>Request correction of inaccurate or incomplete data.</li>
                    <li>Request deletion of your personal data.</li>
                    <li>Object to the processing of your data for specific purposes.</li>
                    <li>Withdraw consent where processing is based on consent.</li>
                </ul>

                <h2 style={styles.subheading}>6. Data Security</h2>
                <p>
                    We implement appropriate technical and organizational measures to safeguard your data. However, no method of transmission or storage is 100% secure.
                </p>

                <h2 style={styles.subheading}>7. Cookies and Tracking Technologies</h2>
                <p>
                    Ekki uses cookies to enhance user experience, analyze traffic, and personalize content. You can control cookie settings through your browser preferences.
                </p>

                <h2 style={styles.subheading}>8. Third-Party Links</h2>
                <p>
                    Our platform may contain links to third-party websites. We are not responsible for the privacy practices of these websites and encourage you to read their privacy policies.
                </p>

                <h2 style={styles.subheading}>9. Changes to This Policy</h2>
                <p>
                    We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of significant updates via email or through our platform.
                </p>

                <h2 style={styles.subheading}>10. Contact Information</h2>
                <p>
                    If you have any questions or concerns about this Privacy Policy, please contact us at:
                </p>
                <ul style={styles.list}>
                    <li>Email: <a href="mailto:support@ekki.com.ng" style={styles.link}>support@ekki.com.ng</a></li>
                    <li>Phone: +234-816-952-2790</li>
                    <li>Website: <a href="https://ekki.com.ng" style={styles.link}>https://ekki.com.ng</a></li>
                </ul>
                </div>

      </LayoutOne>
    </Fragment>
  )
}

const styles = {
  container: {
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    fontFamily: "'Arial', sans-serif",
    lineHeight: "1.8",
    color: "#333",
  },
  heading: {
    color: "#2a9d8f",
    textAlign: "center",
    marginBottom: "20px",
  },
  subheading: {
    color: "#e76f51",
    marginTop: "20px",
    marginBottom: "10px",
  },
  brand: {
    fontWeight: "bold",
    color: "#264653",
  },
  link: {
    color: "#2a9d8f",
    textDecoration: "none",
  },
  list: {
    marginLeft: "20px",
    listStyleType: "disc",
  },
};

