
import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags'
import ContentWrapper from '../dynamicMetaPage/ContentWrapper';
import './SocialShareStyles.css'; // Import the CSS file for styling
import SocialIcon from './socialIcon';

export default function SocialShare({
    link, 
    text, 
    title, 
    textClass, 
    faIconClass, 
    shareIconType = 1,
    shareIconText = '',
    canOverLay = false,
    metaContentDivOrSpan = 'div',
    shopName = '',
    shopLink = '',
    wrapperClass,
    iconFontSize
    // wrapperStyles = {}
}){
    const [isOpen, setIsOpen] = useState(false);
    const isFacebookEnabled = true;
    const isTwitterEnabled = true;
    const isLinkedinEnabled = true;
    const isWhatsappEnabled = true;

    let shareIconClassName = "fa fa-share-alt";
    if(shareIconType !== 1){
        shareIconClassName= "fa fa-share";
    }
    const handleToggle = () => {
      setIsOpen(!isOpen);
    };
    const [titleToShare, setTitleToShare] = useState('');
    const [linkToShare, setLinkToShare] = useState('');
    const [textToShare, setTextToShare] = useState('');

    useEffect(()=>{
    },[titleToShare, linkToShare, textToShare, isOpen]);

    const shareOnFacebook = () => {        
        setTitleToShare(title);
        setTextToShare(text)
        setLinkToShare(link);
        // console.log('facebook', text)
        
        const data = `https://www.facebook.com/sharer/sharer.php?u=${link}`;
        console.log('facebook data', data);
        window.open(data, '_blank');
    };

    const shareOnTwitter = () => {
        setTitleToShare(title);
        setTextToShare(text)
        setLinkToShare(link);

        const urlEncoded = encodeURIComponent(`${link}`);
        let textEncoded = encodeURIComponent(text);
        const data = `https://twitter.com/intent/tweet?text=${textEncoded}&url=${urlEncoded}`;
        console.log('twitter'+data);
        window.open(data, '_blank');
    };

    const shareOnWhatsApp = () => {
        // console.log('whatsapp text', text)
        setTitleToShare(title);
        setTextToShare(text)
        setLinkToShare(link);

        const data = `text=${encodeURIComponent(`${title} - ${text} visit: ${link} for more`)}`;
        // console.log('whatsapp'+link, 'data', data);
        window.open(`https://wa.me/?${data}`, '_blank');
    };

    const shareOnLinkedIn = () => {
        setTitleToShare(title);
        setTextToShare(text)
        setLinkToShare(link);
        
        const data = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(`${link}`)}`;
        console.log('linked', data);
        window.open(data, '_blank');
    };

  return (
    <span>
        <ContentWrapper divOrSpan={metaContentDivOrSpan}>
            <MetaTags>
                <title>{titleToShare}</title>
                <meta name="description" content={textToShare} />
                <meta name="url" content={shopLink} />
                <meta name="site_name" content={shopName} />
                <meta name="type" content={`website`} />

                <meta property="og:title" content={titleToShare} />
                <meta property="og:description" content={textToShare} />
                <meta property="og:url" content={linkToShare} />
                {/* <meta property="og:image" content="https://yourwebsite.com/path-to-image.jpg" /> */}
                <meta property="og:site_name" content={shopName} />
                <meta property="og:type" content="website" />
            </MetaTags>
        </ContentWrapper> 

        <span onClick={handleToggle} className={`social-media-share ${wrapperClass} ${canOverLay ? 'overlay ' : ''} col-12`}>
            <i className={shareIconClassName} style={{fontSize: iconFontSize}}></i> {shareIconText}
            {isOpen && (
                <span>
                { isFacebookEnabled && <SocialIcon faClass={`fab fa-facebook-f ${faIconClass}`} text="Facebook" callBack={shareOnFacebook} textClass={textClass} /> }
                { isTwitterEnabled && <SocialIcon faClass={`fab fa-twitter  ${faIconClass}`} text="Twitter" callBack={shareOnTwitter} textClass={textClass} /> }
                { isLinkedinEnabled && <SocialIcon faClass={`fab fa-linkedin-in  ${faIconClass}`} text="LinkedIn" callBack={shareOnLinkedIn} textClass={textClass} /> }
                { isWhatsappEnabled && <SocialIcon faClass={`fa fa-whatsapp  ${faIconClass}`} text="Whatsapp" callBack={shareOnWhatsApp} textClass={textClass} /> }
            </span>
            )}
        </span>
    </span>
  );
};
