import React from "react";

export default function ContentWrapper({children, divOrSpan = 'div'}){
    if(divOrSpan === 'div'){
        return (
            <div>{children}</div>
        );
    }else{
        return (
            <span>{children}</span>
        );
    }    
}