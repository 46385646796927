import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

const FooterCopyright = ({ footerLogo, spaceBottomClass, colorClass }) => {
  return (
    <div
      className={`copyright ${spaceBottomClass ? spaceBottomClass : ''} ${
        colorClass ? colorClass : ''
      }`}
    >
      <div className="footer-logo font">
        <Link to={'/'}>
          {/* <img alt="" src={footerLogo} /> */}
          Ekki
        </Link>
      </div>
      <p> 
        © {new Date().getFullYear()}{' '}
        <a href="#/" rel="noopener noreferrer" target="_blank">
          Ekki
        </a>
        .<br /> All Rights Reserved
      </p>
    </div>
  )
}

FooterCopyright.propTypes = {
  footerLogo: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string,
}

export default FooterCopyright
