
import React from 'react';
import SearchForm from './SearchForm';

export default function HeaderSearch(){
    return <>
        <div className="search-content active d-block d-lg-none" style={{left: 0}}>
          <SearchForm  />
        </div>
 
        <div className="search-content active d-none d-lg-block" style={{right: 0}}>
          <SearchForm />
        </div>
      </>
} 