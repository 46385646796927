import React from "react";
import { changeLanguage } from "redux-multilanguage";
import { useDispatch } from "react-redux";
import axios from 'axios';
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { setCurrencyReducer } from "../../../redux2/currencySlice";

export default function LanguageCurrencyChanger ({ currentLanguageCode = 'en' }) {
  const dispatch = useDispatch();
  const currency = useSelector((state)=> state.currencyData); 
  const shop = useSelector((state)=> state.shop); 
  const appDetails = JSON.parse(process.env.REACT_APP_APP_DETAILS);
  
  const changeLanguageTrigger = e => {
    const languageCode = e.target.value;
    dispatch(changeLanguage(languageCode));
  };

  const setCurrencyTrigger = async e => {
    const currencyName = e.target.value;
    const currencyRate = await getCurrencyRate(currencyName);
    console.log('cur rate:', currencyRate);
    dispatch(setCurrencyReducer({
      currencyName,
      currencyRate
    }));
  };

  const getCurrencyRate = async (currencyName) => {
    const exchangeRateApiDetails = JSON.parse(process.env.REACT_APP_EXCHANGE_RATE_API_DETAILS);
    try{

      // http://api.exchangeratesapi.io/latest?access_key=a7c82c242db62640f89d65c0583ecf3e&base=EUR

      // http://api.exchangeratesapi.io/latest?base=EUR&access_key=a7c82c242db62640f89d65c0583ecf3e


      // const res = await axios.get(`http://api.exchangeratesapi.io/latest?access_key=``&base=${currencyName}`,{
        const res = await axios.get(`${exchangeRateApiDetails.baseUrl}latest?access_key=${exchangeRateApiDetails.apiKey}&base=${currencyName}`);
      console.log('res:', res.data);
                const rates = res.data.rates
          let currencyRate = 1;
          for (const rate in rates) {
            console.log('r2', rate);
            if (rate === currencyName) {
              currencyRate = rates[rate]
            }
          }
          console.log('r:', currencyRate);
          return currencyRate;
    }catch(err){
      console.error('err', err);
      return 1;
    }

    // return () => {
    //   axios.get(`https://api.exchangeratesapi.io/latest?base=NGN`)
    //     .then((response) => {
    //       const rates = response.data.rates
    //       let currencyRate = 0
    //       for (const rate in rates) {
    //         if (rate === currencyName) {
    //           currencyRate = rates[rate]
    //         }
    //       }
    //       console.log('r:', currencyRate);
    //       return currencyRate;
    //     })
    //     .catch((err) => {
    //       console.log('Error: ', err)
    //     })
    // }
  }

  return (
    <div className="language-currency-wrap">
      <div className="same-language-currency language-style ">
          <span className="d-none d-lg-block">
            {currentLanguageCode === "en"
              ? "English"
              : currentLanguageCode === "fn"
              ? "French"
              : currentLanguageCode === "de"
              ? "Germany"
              : ""}{" "}
            <i className="fa fa-angle-down" />
          </span>

          <span className="d-block d-lg-none">
            {currentLanguageCode === "en"
              ? "Eng"
              : currentLanguageCode === "fn"
              ? "French"
              : currentLanguageCode === "de"
              ? "Germany"
              : ""}{" "}
            <i className="fa fa-angle-down" />
          </span>

        <div className="lang-car-dropdown">
          <ul>
            <li>
              <button value="en" onClick={e => changeLanguageTrigger(e)}>
                English
              </button>
            </li>
            {
              process.env.NODE_ENV === 'development' && 
              <>
                  <li>
                    <button value="fn" onClick={e => changeLanguageTrigger(e)}>
                      French
                    </button>
                  </li>
                  <li>
                    <button value="de" onClick={e => changeLanguageTrigger(e)}>
                      Germany
                    </button>
                  </li>
              </>
            }
          </ul>
        </div>
      </div>

      <div className="same-language-currency use-style col">
        <span>
          {currency?.currencyName} <i className="fa fa-angle-down" />
        </span>
        <div className="lang-car-dropdown">
          <ul>
            <li>
              <button value="NGN" onClick={e => setCurrencyTrigger(e)}>
                NGN
              </button>
            </li>
            {/* <li>
              <button value="USD" onClick={e => setCurrencyTrigger(e)}>
                USD
              </button>
            </li>
            <li>
              <button value="EUR" onClick={e => setCurrencyTrigger(e)}>
                EUR
              </button>
            </li>
            <li>
              <button value="GBP" onClick={e => setCurrencyTrigger(e)}>
                GBP
              </button>
            </li> */}
          </ul>
        </div>
      </div>
      
      <div className="same-language-currency col">
        {
          shop.phone && 
            <div className="text-center">              
              <strong className="text-danger" style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <FontAwesomeIcon icon={faPhone} size="1x" color="green" />{shop.phone}
              </strong>
            </div>
        }
        <div className="same-language-currency d-block d-md-none">
          <p>
            <strong>
              <a 
                href={appDetails.dashboardUrl} 
                target="_blank" 
                rel="noreferrer noopener"
                className="text-info"
              >
                Sell on {appDetails.name}
              </a>
            </strong>
          </p>
        </div>
      </div>

      <div className="same-language-currency d-none d-md-block">
        <p>
          <strong>
            <a 
              href={appDetails.dashboardUrl} 
              target="_blank" 
              rel="noreferrer noopener"
            >
              Sell on {appDetails.name}
            </a>
          </strong>
        </p>
      </div>
    </div>
  );
};
