import React, { useEffect, useState } from 'react'
import Logo from '../../components/header/Logo'
import NavMenu from '../../components/header/NavMenu'
import IconGroup from '../../components/header/IconGroup'
import MobileMenu from '../../components/header/MobileMenu'
import HeaderTop from '../../components/header/HeaderTop'
import { useSelector } from 'react-redux'

export default function HeaderOne ({
  layout,
  top,
  borderStyle,
  headerPaddingClass,
  headerPositionClass,
  headerBgClass,
}){
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const shop = useSelector((state)=> state?.shop);

  useEffect(() => {
    const header = document.querySelector('.sticky-bar')
    setHeaderTop(header.offsetTop)
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    setScroll(window.scrollY)
  }

  return (
    <header
      className={`header-area clearfix ${headerBgClass ? headerBgClass : ''} ${
        headerPositionClass ? headerPositionClass : ''
      }`}
    >
      <div
        className={`${headerPaddingClass ? headerPaddingClass : ''} ${
          top === 'visible' ? 'd-block d-lg-block' : 'd-block'
        } header-top-area ${
          borderStyle === 'fluid-border' ? 'border-none' : ''
        }`}
      >
        <div className={layout === 'container-fluid' ? layout : 'container'}>
          {/* header top */}
          <HeaderTop borderStyle={borderStyle} />          
        </div>
      </div>

      <div
        className={` ${
          headerPaddingClass ? headerPaddingClass : ''
        } sticky-bar header-res-padding clearfix ${
          scroll > headerTop ? 'stick' : ''
        }`}
      >
        <div className={layout === 'container-fluid' ? layout : 'container'}>
          <div className="row">
            <div className="col-xl-2 col-lg-2 col-md-6 col-4">
              <Logo
                imageUrl={shop.defaultImage ? process.env.REACT_APP_API_ASSETS_PATH+shop.defaultImage : '/assets/img/logo/logo.png'}
                logoClass="logo font"
              />
            </div>
            <div className="col-xl-8 col-lg-8 d-none d-lg-block">
              {/* Nav menu */}
              <NavMenu />
            </div>

            <div className="col-xl-2 col-lg-2 col-md-6 col-8">
              {/* Icon group */}
              <IconGroup />
            </div>
          </div>
        </div>
        {/* mobile menu */}
        <MobileMenu />
      </div>
    </header>
  )
}
