import React from "react";
// import { multilanguage } from "redux-multilanguage";
// import { setCurrency } from "../../redux/actions/currencyActions";
import LanguageCurrencyChanger from "./sub-components/LanguageCurrencyChanger";
import numeral from "numeral";
import { useSelector } from "react-redux";
import SocialShare from "../share/SocialShare";

export default function HeaderTop({
  currency,
  setCurrency,
  currentLanguageCode,
  dispatch,
  borderStyle
}){
  const shop = useSelector((state)=> state.shop);  
  // console.log('ss', shop);
  const shareText = `🌟 Shop Smart, Shop ${shop?.name?.toUpperCase()}! 🌟 Looking for the best deals on high-quality products? Look no further! 🛍️ Visit ${shop.url} and explore a wide range of items! 🎉 🚀 Why Shop at ${shop.name}? Trusted, unbeatable prices, Easy-to-use platform for a smooth shopping experience, Secure payments and fast delivery to your doorstep 🚚 Don't miss out on amazing offers! Head to ${shop.url} today and start shopping. 📲✨ #${shop.name} #OnlineShopping #ShopSmart #QualityProducts #Deals #ShopAt${shop.name}`;

  return (
    <div
      className={`row header-top-wap ${
        borderStyle === "fluid-border" ? "border-bottom" : ""
      }`}
    >
      <div className="col-12 col-lg-5">
        <LanguageCurrencyChanger
          currency={currency}
          setCurrency={setCurrency}
          currentLanguageCode={currentLanguageCode}
          dispatch={dispatch}
        />
      </div>
      {
        shop && 
        <>
          <div className="col-12 col-lg-6 mt-3 mt-lg-0 p-1">
          <div className="header-offer">
            <p>
              Get Free delivery on order over{" "}
              <span>
                NGN {numeral(200000).format('0,0')}                  
              </span>
            </p>
          </div>
        </div>    
        <div className="col d-none d-lg-block mt-3 mt-lg-0 p-1">
          <span>
            <SocialShare
              shareIconType={2}
              shareIconText="Share"
              faIconClass="text-light"
              textClass="text-light"
              link={`${shop.url}`} 
              text={shareText} 
              title={shop.name} 
              canOverLay={true}
              metaContentDivOrSpan="span"
              wrapperClass={`bg-danger text-light`}
              // wrapperStyles={{width: '200px'}}
            />  
          </span>
        </div>
        </>
      }
    </div>
  );
};