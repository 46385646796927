
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import axios from 'axios';

export default function SearchForm(){
    const shop = useSelector((state)=> state.shop);
    const [searchedResults, setSearchedResults] = useState([]);

    const searchProducts = async (searchString) => {
        try{
          const res = await axios.get(`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}product/search/public?searchString=${searchString}&shopSlug=${shop?.slug}`);
          
          if(res.status === +process.env.REACT_APP_RECORD_NOT_FOUND){
            // no results found
            setSearchedResults([]);
          }else{
            const { error, data } = res.data;
    
            if(!error){
              setSearchedResults(data);
            }
          }
    
        }catch(err){
          console.error(err);
        }
    }

    return <>    
        <form action="/search">
                <input 
                    placeholder="Search for products..." 
                    name="text"
                    onKeyUp={(e)=>{
                        if(e.target.value.length > 0){
                            searchProducts(e.target.value);
                        }else{
                            setSearchedResults([]);
                        }
                    }}
                />
                <button className="button-search">
                    <i className="pe-7s-search" />
                </button>
        </form>
        {
        searchedResults.length > 0 && 
            <div 
                className="text-black bg-light col-md-12 p-1 m-0"              
                style={{
                    overflowY: 'scroll',
                    overflowX: 'scroll',
                    maxHeight: 300,
                    zIndex: 1000,
                    position: 'absolute',
                    // fontWeight: "bolder",
                    opacity: 0.9
                }}
                > 
                    {
                    searchedResults.map((item)=>
                        (
                        <div key={item.id}  title ="Click to load product ">
                            <Link to={`/product/${item.slug}`}>
                                <div 
                                    className ="col-12 m-0 p-1" 
                                    style={{float: 'right'}}
                                >
                                    <div className ="row col-12 m-0 p-0 ">
                                        <div 
                                            className="col-12 mb-1 pb-2"
                                            style={{
                                            float: 'right',
                                            //   height: 60,
                                            paddingLeft: 20,
                                            lineHeight: 1,
                                            borderBottom: '1px solid grey'
                                        }}
                                        >
                                            <div                                         
                                                style={{
                                                fontSize: 16,
                                                fontWeight: 'bolder'
                                                }}
                                            >
                                                {item.name}
                                            </div>        
                                            {
                                                item.shortDescription && 
                                                <div className='pl-10 truncate-text'>
                                                {
                                                    item.shortDescription
                                                }
                                                </div>
                                            }
                                        </div>
                                    </div>                                
                                </div>
                            </Link> 
                        </div>
                        )
                    )
                    }
                </div>
        }
    </>
}