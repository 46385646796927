import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { logoutUser } from "../../redux2/authSlice";

export default function UserAvatarAndMenu({wrapperClassName}){
    const user = useSelector((state)=> state?.authData?.user);
    const dispatch = useDispatch()
    const handleClick = e => {
        e.currentTarget.nextSibling.classList.toggle("active");
        console.log('e', e);
      };

    const logout = async () => {
        try{
            axios.post(`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}auth/logout`);    

            dispatch(logoutUser());
            localStorage.removeItem('accessToken');  

        }catch(err){
            console.error(err);
        }
    }      

    if(!user){
        return '';
    }
    
    return <>
        <div className={`same-style header-search ${wrapperClassName}`} style={{ color:'#a749ff'  }}>
            {user.firstName}
        </div>        
    
        <div className="same-style account-setting ">            
            <button
                className={`account-setting-active ${wrapperClassName}`}
                onClick={e => handleClick(e)}
            >
                <i className="pe-7s-user-female" />
            </button>
            <div className="account-dropdown">
                <ul>
                    <li>
                        <Link to={"/my-account"}>
                        my account
                        </Link>
                    </li>              
                    <li>
                        <Link to={"#"} onClick={logout}>
                        Logout
                        </Link>
                    </li>              
                </ul>
            </div>            
        </div>
    </>
}