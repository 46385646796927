import React, {useState} from "react";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import { useSelector } from "react-redux";
import axios from 'axios';
import { Link } from "react-router-dom";
import { basicSchema } from "./validation-schemas/LoginRegister";
import GoogleLoginButton from "./googleLoginButton";
import PhoneNumberForm from "./PhoneNumberForm";
import { storeUser } from "../../helpers/utils";
 
export default function LoginForm ({setShowModal, setUser}) {
  const user = useSelector((state)=> state?.authData?.user);
  const [u, setU] = useState('');
  const [token, setToken] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [phoneUpdateRequired, setPhoneUpdateRequired] = useState(false);
  const { addToast } = useToasts(); 
  const appDetails = JSON.parse(process.env.REACT_APP_APP_DETAILS);

  const onSubmit = async (values, actions) => {
    try{
      const { email, password } = values;
      setMessage('');
      setLoading(true);
      const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}auth/login`, {
          username: email,
          password
      });
      setLoading(false);
      if(res.status === +process.env.REACT_APP_RECORD_NOT_FOUND){
        addToast('Invalid details supplied', { appearance: "error", autoDismiss: true });
        alert('Invalid details supplied');
      }else{
        const { error, data, message } = res.data;
    
        if(!error){  
          if(!res.data.data.user.phone){
            setU(res.data.data.user);
            setToken(data.accessToken);
            setPhoneUpdateRequired(true);
            addToast('Please provide your phone number', { appearance: "info", autoDismiss: true });
            return;
          }

          storeUser({accessToken: data.accessToken, user: data.user, setShowModal, actions, setUser});          
        }else{
          setMessage(message);
          addToast(message, { appearance: "error", autoDismiss: true });
        }
      }  
    }catch(err){
      setLoading(false);
      const message = err.response.data.message || err.message;
      console.error(err);
      setMessage(message);
      addToast(message, { appearance: "error", autoDismiss: true });
    }
  };

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: basicSchema,
      onSubmit, 
    });

  const { email, password } = values;

  return (
    <>
        {
          !phoneUpdateRequired ?
          <div>
          <form onSubmit={handleSubmit}>      
            <div>
              <label
                htmlFor="email"
              >
                Email:
              </label>
              <input
                id="email"
                type='text'
                name='email'
                placeholder='Enter your Email'
                onChange={(e)=>{
                  handleChange(e);
                  setMessage('');
                }}
                onBlur={handleBlur}
                value={email}
                // className={`${errors.email && touched.email ? "is-invalid" : ""}`}
              />
              {errors?.email && touched?.email && 
                <span className='text-danger'>{errors?.email}</span>
              }
            </div>

          <div>
              <label
                
                htmlFor="password"
              >
                Password:
              </label>
            <input
              id="password"
              type='password'
              name='password'
              placeholder='Password'
              onChange={(e)=>{
                handleChange(e);
                setMessage('');
              }}
              onBlur={handleBlur}
              value={password}
              className={`${
                errors?.password && touched?.password ? "is-invalid" : ""
              }`}
            />
            {errors?.password && touched?.password && 
              <span className='text-danger'>{errors?.password}</span>
            }
          </div>
          {
            message && 
            <div className="text-center">
              {message}
            </div>
          }

          <div>                            
              <a 
                href={`https://greatftech.com/auth/password/forgot?redirUrl=${appDetails.publicUrl}`}
                target="_blank" 
                rel="noreferrer noopener"
              >
                Forgot Password?
              </a>
            </div>

          <div className='button-box text-center'>            
            <button type='submit' className="btn btn-lg btn-info">
              <span>{loading ? 'Please Wait...' : 'Login'}</span>
            </button>
          </div>    

          <div className="text-right my-2">                            
              <Link to="/register">
                Don't have an account? Register Now
              </Link>
            </div>

          </form>
            {
              !user && <div className="my-2 text-center"> 
                <GoogleLoginButton setUser={setUser} setShowModal={setShowModal} /> 
              </div>
            }
          </div>
          
        :
          <PhoneNumberForm 
            user={u} 
            token={token} 
            setShowModal={setShowModal} 
            setUser={setUser}
          />
        }      
    </>
  );
};
