import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import { store } from '../redux2/store';
import { fetchedLoggedInUser } from '../redux2/authSlice';

export function shareContent() {
    if (navigator.share) {
      navigator.share({
        title: 'Check this out!',
        text: 'Here’s something interesting!',
        url: 'https://example.com',
      })
      .then(() => console.log('Content shared successfully!'))
      .catch((error) => console.error('Error sharing content:', error));
    } else {
      console.log('Web Share API is not supported on this browser.');
    }
}

export function shareFiles() {
    if (navigator.canShare && navigator.canShare({ files: [new File([], '')] })) {
      const filesArray = [
        new File([/* file content */], 'image.png', { type: 'image/png' }),
        new File([/* file content */], 'video.mp4', { type: 'video/mp4' })
      ];
  
      navigator.share({
        files: filesArray,
        title: 'Media files',
        text: 'Check out these files!',
      })
      .then(() => console.log('Files shared successfully!'))
      .catch((error) => console.error('Error sharing files:', error));
    } else {
      console.log('File sharing is not supported on this browser.');
    }
}

export function handlePrint ({containerId}){
  let printContents = document.getElementById(containerId).innerHTML;
  let originalContents = document.body.innerHTML;
  document.body.innerHTML = printContents;
  window.print();
  document.body.innerHTML = originalContents; 
}

export function downloadPDF({pdfRef, fileName = 'rccg-eremittance.pdf'}){
  const validFileExt = '.pdf';
  const ext = fileName.substring(fileName.length-4, fileName.length);
  fileName = validFileExt === ext ? fileName : fileName+validFileExt;
  const input = pdfRef.current;

  html2canvas(input).then((canvas)=>{
      const imgData = canvas.toDataURL('image/png');
      const pdf = new JsPDF('p', 'mm', 'a4', true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 0; // or 30 but to top margin will be high
      pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
      pdf.save(fileName);
  });
}

export function storeUser ({accessToken, user, setShowModal, actions, setUser}){
  const {dispatch} = store;
    localStorage.setItem('accessToken', accessToken);  
    dispatch(fetchedLoggedInUser({accessToken, user}));

    if(actions){
      actions.resetForm();
    }    
  
    if(setShowModal){
      setShowModal(false);
    }

    if(setUser){
      setUser(user);
    }
  }