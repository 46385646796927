import * as yup from 'yup'

const passwordMatches = /^(?=.*\d)(?=.*[a-z])(?=.*[a-z]).{5,}$/;

export const basicSchema = yup.object().shape({
    email:yup.string().required('Email is required'),
    password:yup.string().min(5, 'Password must be 5 letters and more.').required('Password is required')

    /*
    .matches(passwordMatches, {
        message: "Password must contain letter, special characters and numbers",
      })
    */
})

export const phoneNumberValidationSchema = yup.object().shape({
    phone:yup.string().required('Phone is required'),    

    /*
    .matches(passwordMatches, {
        message: "Password must contain letter, special characters and numbers",
      })
    */
})

export const registerSchema = yup.object().shape({
    email:yup.string().required('Email is required'),
    firstname:yup.string().min(3).required('First Name is required.'),
    lastname:yup.string().min(3).required('Last Name is required.'),
    password:yup.string().min(5, 'Password must be at least 5 characters').matches(passwordMatches, { message: "Password must contain letter, special characters and numbers",
      }).required('Password is required'),
    
    phoneNumber:yup.string().min(10, 'Phone Number must be 11 digit.').required('Phone Number is required'),
    cpassword: yup
    .string()
    .min(5, 'Confirm password must be at least 5 characters')
    .oneOf([yup.ref("password"), null],   "Passwords don't match." )
    .required("Confirm password is required."),
})