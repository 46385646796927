import React from "react"

export default function SocialIcon({text, faClass, callBack, textClass ="text-light"}){    
    return <a
        href={`#/`}
        className="social-icon "   
        onClick={()=>callBack()}
        style={{textDecoration: 'none'}}
    >
        <i className={faClass} onClick={callBack}></i>  
        <span className={textClass}>{text}</span>
</a>
}