import React, { useState} from "react";
import { Modal } from "react-bootstrap";
import PhoneNumberForm from "./PhoneNumberForm";
import Button from 'react-bootstrap/Button';

export default function PhoneNumberUpdateModal ({user, token}) {
    const [showModal, setShowModal] = useState(true);
    
    return <Modal
            show={showModal}
            onHide={()=> {
            setShowModal(false);
            // setShowLoginRegisterModal(false);
        }}
        className="product-quickview-modal-wrapper"
    >
        <Modal.Header closeButton>
        <Modal.Title>
            <span className='text-info'>Please update your phone number</span>
        </Modal.Title>
        </Modal.Header> 

        <Modal.Body>
            <div className="modal-body">
            <div className="login-register-area">
            <div className="container">
                <div className="row">
                <div className="col-lg-7 col-md-12 ml-auto mr-auto">
                    <div className="login-register-wrapper">
                        <PhoneNumberForm user={user} token={token}setShowModal={setShowModal} />
                    </div>
                </div>
                </div>
            </div>
            </div>
            </div>
        </Modal.Body>
        
        <Modal.Footer>
        <Button variant="secondary" onClick={()=>{
            setShowModal(false);
            // setShowLoginRegisterModal(false);
        }}>Close</Button>
        </Modal.Footer>
    </Modal>  
};