import React, { Fragment } from 'react'
import MetaTags from 'react-meta-tags'
import LayoutOne from '../../../layouts/LayoutOne'
import ContentWrapper from '../../../components/dynamicMetaPage/ContentWrapper'

export default function CookiePolicy () {  
  const appDetails = JSON.parse(process.env.REACT_APP_APP_DETAILS)
  const metaTags = <MetaTags>
      <title>{appDetails.name} | Terms and Conditions</title>
      <meta
        name="description"
        content={appDetails.name}
      />
    </MetaTags>

  return (
    <Fragment>
      <ContentWrapper>{metaTags}</ContentWrapper>

      <LayoutOne 
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
        headerTop="visible"
      >
    <div style={styles.container} className="cookie-policy">
      <h1>Cookie Consent Policy</h1>
      <p><strong>Effective Date:</strong> January 21st, 2025</p>
      <p>
        At Ekki ({appDetails.publicUrl}), we use cookies to enhance your experience on our platform and to ensure that you have the most seamless interaction with our services. This Cookie Consent Policy explains what cookies are, how we use them, and how you can manage your cookie preferences.
      </p>
      <h2>What Are Cookies?</h2>
      <p>
        Cookies are small text files placed on your device when you visit a website. They help websites remember your preferences and actions, making your experience more personalized and efficient.
      </p>
      <h2>Types of Cookies We Use</h2>
      <ul>
        <li><strong>Essential Cookies:</strong> Necessary for the basic functionality of our website.</li>
        <li><strong>Performance Cookies:</strong> Collect information about how you use our website to improve performance.</li>
        <li><strong>Functional Cookies:</strong> Remember your choices to provide a more personalized experience.</li>
        <li><strong>Advertising Cookies:</strong> Deliver relevant ads based on your interests.</li>
      </ul>
      <h2>Managing Your Cookie Preferences</h2>
      <p>
        You can manage your cookie preferences through your browser settings or opt-out links provided by third-party cookie providers. Note that disabling cookies may impact your experience on our website.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions about our use of cookies, please contact us at:
      </p>
      <p>
        <ul style={styles.list}>
            <li>Email: <a href="mailto:support@ekki.com.ng" style={styles.link}>support@ekki.com.ng</a></li>
            <li>Phone: +234-816-952-2790</li>
            <li>Website: <a href="https://ekki.com.ng" style={styles.link}>https://ekki.com.ng</a></li>
        </ul>
      </p>
    </div>          

      </LayoutOne>
    </Fragment>
  )
}

const styles = {
  container: {
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    fontFamily: "'Arial', sans-serif",
    lineHeight: "1.8",
    color: "#333",
  },
  heading: {
    color: "#2a9d8f",
    textAlign: "center",
    marginBottom: "20px",
  },
  subheading: {
    color: "#e76f51",
    marginTop: "20px",
    marginBottom: "10px",
  },
  brand: {
    fontWeight: "bold",
    color: "#264653",
  },
  link: {
    color: "#2a9d8f",
    textDecoration: "none",
  },
  list: {
    marginLeft: "20px",
    listStyleType: "disc",
  },
};
