import React from "react";
import { Link } from "react-router-dom";
import { useSelector} from "react-redux";
import MenuCart from "./sub-components/MenuCart";
import HeaderSearch from "./HeaderSearch";
import SocialShare from "../share/SocialShare";
import UserAvatarAndMenu from "./UserAvatarAndMenu";

export default function IconGroup ({ iconWhiteClass }){
  const shop = useSelector((state)=> state.shop);  
  const canHideSearchField = false;
  const shareText = `🌟 Shop Smart, Shop ${shop.name?.toUpperCase()}! 🌟 Looking for the best deals on high-quality products? Look no further! 🛍️ Visit ${shop.url} and explore a wide range of items! 🎉 🚀 Why Shop at ${shop.name}? Trusted, unbeatable prices, Easy-to-use platform for a smooth shopping experience, Secure payments and fast delivery to your doorstep 🚚 Don't miss out on amazing offers! Head to ${shop.url} today and start shopping. 📲✨ #${shop.name} #OnlineShopping #ShopSmart #QualityProducts #Deals #ShopAt${shop.name}`;
  // const shareText = `Shop Smart, Shop ${shop.name.toUpperCase()}! Looking for the best deals on high-quality products? Look no further! Visit ${shop.url} and explore a wide range of items! Why Shop at ${shop.name}? Trusted, unbeatable prices, Easy-to-use platform for a smooth shopping experience, Secure payments and fast delivery to your doorstep Don't miss out on amazing offers! Head to ${shop.url} today and start shopping. #${shop.name} #OnlineShopping #ShopSmart #QualityProducts #Deals #ShopAt${shop.name}`;
  const cartData = useSelector((state)=> state.cartData);
  const wishlistData = (useSelector((state)=> state.wishlistData));
  const compareData = (useSelector((state)=> state.compareData));

  const handleClick = e => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };

  const triggerMobileMenu = () => {
    console.log('trigger open mobile side bar menu');
    const offcanvasMobileMenu = document.querySelector("#offcanvas-mobile-menu");
    offcanvasMobileMenu.classList.add("active");
  };

  return (
    <div className={`header-right-wrap ${iconWhiteClass ? iconWhiteClass : ""}`}>
      <div className="same-style header-search">
        {
          canHideSearchField && 
          <button className="search-active" onClick={e => handleClick(e)}>
            <i className="pe-7s-search" />
          </button>        
        }        
        <HeaderSearch />
      </div>
      
      <UserAvatarAndMenu wrapperClassName="d-none d-lg-block" />
      
      <div className="d-none d-sm-block same-style cart-wrap">
        <button className="icon-cart" onClick={e => handleClick(e)}>
          <i className={`fa fa-shopping-cart ${cartData.length > 0 ? 'text-info' : ''}`}></i>
          <i className="fa fa-cart" />
          <span className="count-style">
            {cartData && cartData.length ? cartData.length : 0}
          </span>
        </button>
        <MenuCart />        
      </div>

      <div className="same-style header-compare">
        <Link to={"/compare"}>
          <i className="pe-7s-shuffle" />
          <span className="count-style">
            {compareData && compareData.length ? compareData.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style header-wishlist">
        <Link to={"/wishlist"}>
          <i className="pe-7s-like" />
          <span className="count-style">
            {wishlistData && wishlistData.length ? wishlistData.length : 0}
          </span>
        </Link>
      </div>

      <div className="d-block d-sm-none same-style cart-wrap">
        <button className="icon-cart" onClick={e => handleClick(e)}>
          <i className={`fa fa-shopping-cart ${cartData.length > 0 ? 'text-info' : ''}`}></i>
          <i className="fa fa-cart" />
          <span className="count-style">
            {cartData && cartData.length ? cartData.length : 0}
          </span>
        </button>
        <MenuCart />        
      </div>
      <div className="same-style cart-wrap d-block d-lg-none">
        <Link to={"#/"}>
            <SocialShare
              faIconClass="text-danger"
              textClass="text-danger"
              iconFontSize="20px"
              link={`${shop.url}`} 
              text={shareText} 
              title={shop.name} 
            />  
        </Link>       
      </div>

      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div>  
    </div>
  );
};
