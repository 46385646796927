import React from 'react';
// import { useState } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { fetchedLoggedInUser } from '../../redux2/authSlice';

const GoogleLoginButton = ({setUser, closeModal}) => {
    const dispatch = useDispatch();

    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>   
            <GoogleLogin
            onSuccess={ async credentialResponse => {
                try{
                    const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}auth/google-login`,
                        {
                            token: credentialResponse.credential
                        }
                    );
                    
                if(res.status === +process.env.REACT_APP_RECORD_NOT_FOUND){
                    if (window.confirm("Sorry, we're unable to find your account. Would you like to register with this email?") === true) {
                        console.log('do register');
                        const regRes = await axios.post(`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}auth/google-register`,
                        {
                            token: credentialResponse.credential
                        }
                    );

                    if(regRes.status === +process.env.REACT_APP_RECORD_NOT_FOUND){
                        alert('Sorry unable to register you please use the registration form');
                    }else{
                    const { error, data } = res.data;
                
                    if(!error){    
                        localStorage.setItem('accessToken', data.accessToken);  
                        dispatch(fetchedLoggedInUser(data)); 
                        if(setUser){
                            setUser(data);
                        }     
                        
                        if(closeModal){
                            closeModal(true);
                        }
                    }
                }

                    } else {
                        console.log('do nothing');
                    }
                }else{
                    const { error, data } = res.data;
                
                    if(!error){    
                        localStorage.setItem('accessToken', data.accessToken);  
                        dispatch(fetchedLoggedInUser(data)); 
                        if(setUser){
                            setUser(data);
                        }     
                        
                        if(closeModal){
                            closeModal(true);
                        }
                    }
                }
            }catch(err){
                console.error(err);
            }
            }}
            onError={() => {
                console.log('Login Failed');
            }}
            />;
      </GoogleOAuthProvider>
    );
}

export default GoogleLoginButton;